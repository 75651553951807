import React, { useState } from "react"
import { Input, Stack, Box, StackDivider } from "@chakra-ui/react"
import { HexColorPicker } from "react-colorful"
import { Frame } from "../../components/wrappers/Frame"

function ColorWheelDemo() {
    const [value, SetValue] = useState("#4299e1")

    function handleChange(value: string) {
        SetValue(value)
    }

    return (
        <Frame>
            <Stack
                direction={["column", null, "row"]}
                spacing={8}
                alignItems="center"
                borderColor="inherit"
                divider={<StackDivider />}
            >
                <Input
                    value={value}
                    onChange={e => handleChange(e.target.value)}
                />
                <Box flex={1}>
                    <HexColorPicker color={value} onChange={handleChange} />
                </Box>
            </Stack>
        </Frame>
    )
}

export { ColorWheelDemo }
